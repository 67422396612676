import Vue from "vue";

const SET_ENGINE_3D_PLUGIN_LOADING = 'setEngine3DPluginLoading';

export default ({ app, store }, inject) => {
  inject('engine3d', null);
  app.router.beforeEach(async (to, from, next) => {
    const hasPortfolioPageDisabled = store.state?.base?.meta?.generalConfig?.portfolioPageDisabled || false;
    if(hasPortfolioPageDisabled && !['project-project', 'project-project-space-space', 'project-project-slug', 'slug'].includes(to.name)) {
      try {
        await init3DEngine({ app, store });
        next();
      } catch (error) {
        store.commit(`base/${SET_ENGINE_3D_PLUGIN_LOADING}`, false);
        next(false);
      }
    }
    if (['project-project', 'project-project-space-space'].includes(to.name)) {
      try {
        await init3DEngine({ app, store });
        next();
      } catch (error) {
        store.commit(`base/${SET_ENGINE_3D_PLUGIN_LOADING}`, false);
        next(false);
      }
    } else {
      next();
    }
  });
}


const init3DEngine = async ({ app, store }) => {
  store.commit(`base/${SET_ENGINE_3D_PLUGIN_LOADING}`, true);
  const {BabylonProjectManager} = await import('@bright-spaces/engine-3d');
  Vue.set(app, 'engine3d', BabylonProjectManager);
  window.engine3d = BabylonProjectManager;
  store.commit(`base/${SET_ENGINE_3D_PLUGIN_LOADING}`, false);
}
